import React from "react";
// import { Link } from 'react-router-dom';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

const AboutMe = () => (
  <ReactCSSTransitionGroup
    transitionName="anim"
    transitionAppear={true}
    transitionAppearTimeout={2000}
    transitionEnter={false}
    transitionLeave={false}
  >
    <div className="content-container">
      <div className="about-me-text__content">
        <p className="about-me-item__title">Katerina Bezlepkina</p>
        <p className="about-me__sub-title">FRONT-END DEVELOPER</p>

        <p className="about-me-text__item">
          For more than 4 years I have been working with many remote teams
          separated by different timezones. I had hands on experience in working
          with libraries like React.js, Redux, NextJs, VueJs, Node.js &
          Express.js frameworks, testing suites Jest & Mocha, creating layouts,
          UI design, supporting and troubleshooting front-end web applications.
          As well I had experience of working with NoSQL and SQL databases like
          MongoDB, Firebase, PostgreSQL, Heroku deployments tools, supporting
          web3 API blockchain based apps, Docker, basic familiarity with Python
          & machine-learning algorithms.
        </p>

        <p className="about-me-text__item">
          Except JavaScript, I am also fluent in human languages such as
          Russian, English & Mandarin Chinese.
        </p>

        <p className="about-me-text__item">
          Outside programming I enjoy music, consuming huge amounts of caffeine
          & cooking Korean food.
        </p>
      </div>
    </div>
  </ReactCSSTransitionGroup>
);

export default AboutMe;
