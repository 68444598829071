import React from 'react';
import {  NavLink } from 'react-router-dom';

class HeaderMenu extends React.Component {

  render() {
    return (
      <header className="header">
        <div className="content-container">
          <div className="header__content">

            <NavLink
              to="/"
              exact
              className="header-button"
              activeClassName="selected"
            >
              Home
            </NavLink>

            <NavLink
              to="/about-me"
              className="header-button"
              activeClassName="selected"
            >
              About Me
            </NavLink>

            <NavLink
              to="/my-work"
              className="header-button"
              activeClassName="selected"
            >
              My Work
            </NavLink>

            <NavLink
              to="/contacts"
              className="header-button"
              activeClassName="selected"
            >
              Contacts
            </NavLink>

          </div>
        </div>
      </header>
    );
  }
}



export default HeaderMenu;
