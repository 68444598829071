import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

const Intro = (props) => (
  <div className="content-container">
    <ReactCSSTransitionGroup
      transitionName="anim"
      transitionAppear={true}
      transitionAppearTimeout={2000}
      transitionEnter={false}
      transitionLeave={false}
    >
      <div className="intro__content">
        <img className="intro__img" alt="selfie" src="/images/selfie.png" />
        <p className="intro__sub-title">FRONT-END DEVELOPER</p>

        <h1 className="intro__title">Katerina Bezlepkina</h1>
        <div className="intro-text__container">
          <p className="intro-text__item">Javascript</p>
          <p className="intro-text__item">React</p>
          <p className="intro-text__item">Vue.js developer</p>
        </div>
      </div>
    </ReactCSSTransitionGroup>
  </div>
);

export default Intro;
