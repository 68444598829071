import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

const MyWork = () => (
  <ReactCSSTransitionGroup
    transitionName="anim"
    transitionAppear={true}
    transitionAppearTimeout={2000}
    transitionEnter={false}
    transitionLeave={false}
  >
    <div className="content-container">
      <div className="my-work__content">
        <div className="my-work-item__box">
          <a
            className="my-work-item__link title-link-color"
            href="https://crypto-trends.netlify.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="my-work-item__title-container">
              <div className="my-work-item__title">
                <p>Crypto</p>
                <p>Trends</p>
              </div>
              <i
                className="far fa-newspaper my-work-item__icon fa-4x"
                aria-hidden="true"
              ></i>
            </div>
          </a>
          <p className="my-work-item__text">
            App for checking information about cryptocurencies and latest news
            headlines. Built with VueJS, CryptoCompare & CryptoControl APIs
          </p>
          <p className="my-work-item__text">
            <a
              className="my-work-item__text-link text-link-color"
              href="https://crypto-trends.netlify.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Demo
            </a>
            <a
              className="my-work-item__text-link text-link-color"
              href="https://github.com/Arkeetina/cryptoTrends"
              rel="noopener noreferrer"
              target="_blank"
            >
              Git-Repository
            </a>
          </p>
        </div>

        <div className="my-work-item__box">
          <a
            className="my-work-item__link title-link-color"
            href="https://crypto-calculator.netlify.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="my-work-item__title-container">
              <div className="my-work-item__title">
                <p>Crypto</p>
                <p>Calculator</p>
              </div>
              <i
                className="fab fa-bitcoin my-work-item__icon fa-4x"
                aria-hidden="true"
              ></i>
            </div>
          </a>
          <p className="my-work-item__text">
            Web app for calculating historical Cryptocurrency prices. Built with
            React/Redux
          </p>
          <p className="my-work-item__text">
            <a
              className="my-work-item__text-link text-link-color"
              href="https://crypto-calculator.netlify.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Demo
            </a>
            <a
              className="my-work-item__text-link text-link-color"
              href="https://github.com/Arkeetina/show-me-coins"
              rel="noopener noreferrer"
              target="_blank"
            >
              Git-Repository
            </a>
          </p>
        </div>

        {/* <div className="my-work-item__box">
          <a 
            className="my-work-item__link title-link-color" 
            href="https://send-me-a-song.herokuapp.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="my-work-item__title-container">
              <div className="my-work-item__title">
                <p>Song</p>
                <p>Chatbot</p>
              </div>
              <i className="fa fa-music my-work-item__icon fa-4x" aria-hidden="true"></i>
            </div>
          </a>
            <p className="my-work-item__text">
              Simple React/Redux music chatbot, powered by YouTube API & Firebase. Sends you a song based on keyword provided in message.
            </p>
            <p className="my-work-item__text">
              <a  className="my-work-item__text-link text-link-color" 
                  href="https://send-me-a-song.herokuapp.com/" 
                  rel="noopener noreferrer"
                  target="_blank">
              Demo</a> 
              <a  className="my-work-item__text-link text-link-color" 
                  href="https://github.com/Arkeetina/send-me-a-song" 
                  rel="noopener noreferrer"
                  target="_blank">
              Git-Repository</a>
            </p>
        </div> */}
      </div>
    </div>
  </ReactCSSTransitionGroup>
);

export default MyWork;
