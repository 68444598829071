import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

const Contacts = () => (
  <ReactCSSTransitionGroup
    transitionName="anim"
    transitionAppear={true}
    transitionAppearTimeout={2000}
    transitionEnter={false}
    transitionLeave={false}
  >
    <div className="content-container">
      <div className="contacts__content">
        <div className="contacts-item__box">
          <p className="contacts-item__title">Ways to contact me</p>

          <p className="contacts-item__text">
            <i
              className="fa fa-pencil-square-o contacts-icon-spacing"
              aria-hidden="true"
            />
            Email:{" "}
            <a
              href=""
              rel="noopener noreferrer"
              target="_blank"
              className="contacts-item__link"
            >
              ekaterina.bl@protonmail.com
            </a>
          </p>

          <p className="contacts-item__text">
            <i
              className="fab fa-linkedin-in contacts-icon-spacing"
              aria-hidden="true"
            />
            <a
              className="contacts-item__link"
              href="https://www.linkedin.com/in/ekaterinabezlepkina/"
              target="_blank"
            >
              LinkedIn
            </a>
          </p>

          <p className="contacts-item__text">
            <i
              className="fab fa-github-alt contacts-icon-spacing"
              aria-hidden="true"
            />
            <a
              className="contacts-item__link contact-icon-spacing"
              href="https://github.com/Arkeetina"
              target="_blank"
            >
              GitHub
            </a>
          </p>
        </div>
      </div>
    </div>
  </ReactCSSTransitionGroup>
);

export default Contacts;
